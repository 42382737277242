import { ethers } from 'ethers';
import TOKEN_ABI from '../abis/Token.json';
import EXCHANGE_ABI from '../abis/Exchange.json';

export const loadProvider = (dispatch) => {
  const connection = new ethers.providers.Web3Provider(window.ethereum);
  dispatch({ type: 'PROVIDER_LOADED', connection });

  return connection;
};

export const loadNetwork = async (provider, dispatch) => {
  const { chainId } = await provider.getNetwork();
  dispatch({ type: 'NETWORK_LOADED', chainId });

  return chainId;
};

export const loadAccount = async (provider, dispatch) => {
  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
  const account = ethers.utils.getAddress(accounts[0])

  dispatch({ type: 'ACCOUNT_LOADED', account })

  const latestBlock = await provider.getBlockNumber();
  let balance = await provider.getBalance(account, latestBlock);
  balance = ethers.utils.formatEther(balance)

  dispatch({ type: 'ETHER_BALANCE_LOADED', balance })

  return account
};

export const loadTokens = async (provider, addresses, dispatch) => {
  let token, symbol

  token = new ethers.Contract(addresses[0], TOKEN_ABI, provider)
  symbol = await token.symbol()
  dispatch({ type: 'TOKEN_1_LOADED', token, symbol })

  token = new ethers.Contract(addresses[1], TOKEN_ABI, provider)
  symbol = await token.symbol()
  dispatch({ type: 'TOKEN_2_LOADED', token, symbol })

  return token
}

export const loadExchange = async (provider, address, dispatch) => {
  const exchange = new ethers.Contract(address, EXCHANGE_ABI, provider);
  dispatch({ type: 'EXCHANGE_LOADED', exchange })

  return exchange
}

const decimalsCache = {};

// Function to get decimals with caching and error handling
const getDecimals = async (token) => {
  if (decimalsCache[token.address]) {
    return decimalsCache[token.address];
  }

  try {
    const decimals = await token.decimals();
    decimalsCache[token.address] = decimals; // Cache the value
    return decimals;
  } catch (error) {
    console.error(`Error fetching decimals for token ${token.address}:`, error);
    return 18; // Fallback to 18 decimals
  }
};

export const subscribeToEvents = (exchange, dispatch) => {
  exchange.on('Cancel', (id, user, tokenGet, amountGet, tokenGive, amountGive, timestamp, event) => {
    const order = event.args;
    dispatch({ type: 'ORDER_CANCEL_SUCCESS', order, event });
  });

  exchange.on('Trade', (id, user, tokenGet, amountGet, tokenGive, amountGive, creator, timestamp, event) => {
    const order = event.args;
    dispatch({ type: 'ORDER_FILL_SUCCESS', order, event });
  });

  exchange.on('Deposit', (token, user, amount, balance, event) => {
    dispatch({ type: 'TRANSFER_SUCCESS', event });
  });

  exchange.on('Withdraw', (token, user, amount, balance, event) => {
    dispatch({ type: 'TRANSFER_SUCCESS', event });
  });

  exchange.on('Order', (id, user, tokenGet, amountGet, tokenGive, amountGive, timestamp, event) => {
    const order = event.args;
    dispatch({ type: 'NEW_ORDER_SUCCESS', order, event });
  });
};

// ------------------------------------------------------------------------------
// LOAD USER BALANCES (WALLET & EXCHANGE BALANCES)

export const loadBalances = async (exchange, tokens, account, dispatch) => {
  const token1Decimals = await getDecimals(tokens[0]);
  let balance = ethers.utils.formatUnits(await tokens[0].balanceOf(account), token1Decimals);
  dispatch({ type: 'TOKEN_1_BALANCE_LOADED', balance });

  balance = ethers.utils.formatUnits(await exchange.balanceOf(tokens[0].address, account), token1Decimals);
  dispatch({ type: 'EXCHANGE_TOKEN_1_BALANCE_LOADED', balance });

  const token2Decimals = await getDecimals(tokens[1]);
  balance = ethers.utils.formatUnits(await tokens[1].balanceOf(account), token2Decimals);
  dispatch({ type: 'TOKEN_2_BALANCE_LOADED', balance });

  balance = ethers.utils.formatUnits(await exchange.balanceOf(tokens[1].address, account), token2Decimals);
  dispatch({ type: 'EXCHANGE_TOKEN_2_BALANCE_LOADED', balance });
};

export const loadAllOrders = async (provider, exchange, dispatch) => {
  const latestBlock = await provider.getBlockNumber(); // Fetch the latest block

  const cancelStream = await exchange.queryFilter('Cancel', 0, latestBlock);
  const cancelledOrders = cancelStream.map((event) => event.args);
  dispatch({ type: 'CANCELLED_ORDERS_LOADED', cancelledOrders });

  const tradeStream = await exchange.queryFilter('Trade', 0, latestBlock);
  const filledOrders = tradeStream.map((event) => event.args);
  dispatch({ type: 'FILLED_ORDERS_LOADED', filledOrders });

  const orderStream = await exchange.queryFilter('Order', 0, latestBlock);
  const allOrders = orderStream.map((event) => event.args);
  dispatch({ type: 'ALL_ORDERS_LOADED', allOrders });
};

// ------------------------------------------------------------------------------
// TRANSFER TOKENS (DEPOSIT & WITHDRAWS)

export const transferTokens = async (provider, exchange, transferType, token, amount, dispatch) => {
  dispatch({ type: 'TRANSFER_REQUEST' });

  try {
    const signer = await provider.getSigner();

    const tokenDecimals = await getDecimals(token);
    const amountToTransfer = ethers.utils.parseUnits(amount.toString(), tokenDecimals);

    let transaction;
    if (transferType === 'Deposit') {
      transaction = await token.connect(signer).approve(exchange.address, amountToTransfer);
      await transaction.wait();
      transaction = await exchange.connect(signer).depositToken(token.address, amountToTransfer);
    } else {
      transaction = await exchange.connect(signer).withdrawToken(token.address, amountToTransfer);
    }

    await transaction.wait()

  dispatch({ type: 'TRANSFER_SUCCESS' });

  } catch(error) {
    dispatch({ type: 'TRANSFER_FAIL' })
    console.error(`${transferType} failed:`, error);  // Added error logging here
  }
}


export const makeBuyOrder = async (provider, exchange, tokens, order, dispatch) => {
  try {
    const tokenGetDecimals = await getDecimals(tokens[0]);
    const tokenGiveDecimals = await getDecimals(tokens[1]);

    const tokenGet = tokens[0].address;
    const amountGet = ethers.utils.parseUnits(order.amount.toString(), tokenGetDecimals);
    const tokenGive = tokens[1].address;
    const amountGive = ethers.utils.parseUnits((order.amount * order.price).toString(), tokenGiveDecimals);

    dispatch({ type: 'NEW_ORDER_REQUEST' })

    const signer = await provider.getSigner()
    const transaction = await exchange.connect(signer).makeOrder(tokenGet, amountGet, tokenGive, amountGive)
    await transaction.wait()

  dispatch({ type: 'NEW_ORDER_SUCCESS' });  // Added success dispatch

  } catch (error) {
    dispatch({ type: 'NEW_ORDER_FAIL' });
    console.error('Order failed:', error);  // Added error logging
  }
}

export const makeSellOrder = async (provider, exchange, tokens, order, dispatch) => {
  try {
    const tokenGetDecimals = await getDecimals(tokens[0]);
    const tokenGiveDecimals = await getDecimals(tokens[1]);

    const tokenGet = tokens[1].address;
    const amountGet = ethers.utils.parseUnits((order.amount * order.price).toString(), tokenGiveDecimals);
    const tokenGive = tokens[0].address;
    const amountGive = ethers.utils.parseUnits(order.amount.toString(), tokenGetDecimals);

    dispatch({ type: 'NEW_ORDER_REQUEST' })

    const signer = await provider.getSigner()
    const transaction = await exchange.connect(signer).makeOrder(tokenGet, amountGet, tokenGive, amountGive)
    await transaction.wait()

  dispatch({ type: 'NEW_ORDER_SUCCESS' });  // Added success dispatch

  } catch (error) {
    dispatch({ type: 'NEW_ORDER_FAIL' });
    console.error('Order failed:', error);  // Added error logging
  }
};

export const cancelOrder = async (provider, exchange, order, dispatch) => {

  dispatch({ type: 'ORDER_CANCEL_REQUEST' })

  try {
    const signer = await provider.getSigner()
    const transaction = await exchange.connect(signer).cancelOrder(order.id)
    await transaction.wait()

    dispatch({ type: 'ORDER_CANCEL_SUCCESS' });  // Added success dispatch

  } catch (error) {
    dispatch({ type: 'ORDER_CANCEL_FAIL' });
    console.error('Order cancel failed:', error);  // Added error logging
  }
};



export const fillOrder = async (provider, exchange, order, dispatch) => {
  dispatch({ type: 'ORDER_FILL_REQUEST' })

  try {
    const signer = await provider.getSigner()
    const transaction = await exchange.connect(signer).fillOrder(order.id)
    await transaction.wait()

    dispatch({ type: 'ORDER_FILL_SUCCESS' });  // Added success dispatch

  } catch (error) {
    dispatch({ type: 'ORDER_FILL_FAIL' });
    console.error('Order fill failed:', error);  // Added error logging
  }
};

