import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import config from '../config.json';

import {
  loadProvider, 
  loadNetwork, 
  loadAccount,
  loadTokens,
  loadExchange,
  loadAllOrders,
  subscribeToEvents
} from '../store/interactions';

import Navbar from './Navbar'
import ToughBarImage from '../assets/heartsoftoughbar.svg'
import ToughBarImage1 from '../assets/boxingtoughbar.svg'
import ToughBarImage2 from '../assets/diamondsoftoughbar.svg'
import ToughBarImage3 from '../assets/ladytoughbar.svg'
import ToughBarImage4 from '../assets/toughbar.svg'
import Markets from './Markets'
import Balance from './Balance'
import Order from './Order'
import PriceChart from './PriceChart'
import Transactions from './Transactions'
import Trades from './Trades'
import OrderBook from './OrderBook'
import Alert from './Alert'
import Footer from './Footer'

function App() {
  const dispatch = useDispatch()

  const loadBlockchainData = async () => {

    const provider = loadProvider(dispatch)

    const chainId = await loadNetwork(provider, dispatch)


    window.ethereum.on('chainChanged', () => {
      window.location.reload()
    })

    window.ethereum.on('accountsChanged', () => {
      loadAccount(provider, dispatch)
    })

    
    const BTbar = config[chainId].BTbar
    const WETH = config[chainId].WETH
    await loadTokens(provider, [BTbar.address, WETH.address], dispatch)

    const exchangeConfig = config[chainId].exchange
    const exchange = await loadExchange(provider, exchangeConfig.address, dispatch)

    loadAllOrders(provider, exchange, dispatch)

    subscribeToEvents(exchange, dispatch)
  }

  useEffect(() => {
    loadBlockchainData()
  })

  return (
  <div>

    <Navbar />

    <section id="toughbar-section" className="toughbar-section">
      <h2><span className="toughbar-animated">ToughBar</span></h2>
      
      <div className="toughbar-table">
        <div className="toughbar-corner toughbar-top-left">
          <img src={ToughBarImage} alt="ToughBar" className="toughbar-image" />
        </div>
        <div className="toughbar-corner toughbar-top-right">
          <img src={ToughBarImage1} alt="ToughBar Variant 1" className="toughbar-image" />
        </div>
        <div className="toughbar-corner toughbar-bottom-left">
          <img src={ToughBarImage2} alt="ToughBar Variant 2" className="toughbar-image" />
        </div>
        <div className="toughbar-corner toughbar-bottom-right">
          <img src={ToughBarImage3} alt="ToughBar Variant 3" className="toughbar-image" />
        </div>
        <div className="toughbar-referee">
          <img src={ToughBarImage4} alt="ToughBar Variant 4" className="toughbar-referee-image" />
        </div>
      </div>
      <hr />
      <p>
        <span className="black-text">Experience the toughness and elegance of </span>
        <span className="toughbar-animated">ToughBar</span>
        <span className="black-text">. Perfectly crafted for the rich and respected, with a touch of futuristic charm.</span>
      </p>
    </section>

    <section id="roadmap-section" className="roadmap-section">
      <h2>Project Roadmap</h2>
      <div className="roadmap-container">
        
        <div className="roadmap-phase">
          <h3>Phase 1: Launch & Foundation</h3>
          <p>
            <strong>Timeline:</strong> Q3 2024<br />
            Official launch of Base ToughBar, deploying our native tokens and unveiling the exchange platform. We focus on laying the foundation with secure smart contracts, ensuring a robust and reliable platform for our community. This phase also includes the onboarding of key team members and the setup of our governance structure to empower token holders from the start.
          </p>
        </div>

        <div className="roadmap-phase">
          <h3>Phase 2: Community Engagement & Growth</h3>
          <p>
            <strong>Timeline:</strong> Q4 2024 - Q1 2025<br />
            Building a thriving community through a series of engaging events, interactive educational seminars, and collaborations with blockchain influencers. We will also initiate the development of a card game called Spades, which will be integrated into our ecosystem to provide entertainment and encourage community interaction. This phase is all about making connections and spreading awareness. We aim to cultivate a loyal community that is passionate about blockchain technology and the unique experiences Base ToughBar offers.
          </p>
        </div>

        <div className="roadmap-phase">
          <h3>Phase 3: Platform Expansion, New Features & Educational Outreach</h3>
          <p>
            <strong>Timeline:</strong> Q2 2025 - Q3 2025<br />
            Introducing advanced features like staking, liquidity pools, and enhanced trading tools to increase user engagement and platform utility. In addition to these features, we will launch the initial version of our card game, Spades, enhancing our ecosystems interactive aspects. This phase also marks the beginning of our networking, educational, and entertaining resort, designed for hosting events, seminars, training workshops, conventions, and more. We will also establish networking and workshop hubs across the nation to teach individuals how to utilize blockchain technology within our ecosystem. Our focus will be on continuously enhancing the user experience and adding functionalities that cater to both novice and experienced traders.
          </p>
        </div>

        <div className="roadmap-phase">
          <h3>Phase 4: Scaling, Partnerships & Global Outreach</h3>
          <p>
            <strong>Timeline:</strong> Q4 2025 - Beyond<br />
            Scaling the platform by establishing strategic partnerships with industry leaders, influencers, and key stakeholders. Expanding our reach into promising markets globally while ensuring our platform remains user-friendly and scalable. The resort and workshop hubs will play a crucial role into our outreach strategy, as we aim to provide hands-on learning experiences and networking opportunities worldwide. We will also focus on continuous innovation, adding optimized services, and creating opportunities for our community to thrive within the blockchain space.
          </p>
        </div>
      
      </div>
    </section>
       
    <main className='exchange grid'>
      <section className='exchange__section--left grid'>

        <Markets />

        <Balance />

        <Order />

      </section>
      <section className='exchange__section--right grid'>

        <PriceChart />

        <Transactions />

        <Trades />

        <OrderBook />

      </section>
    </main>

    <Alert />

    <Footer />

  </div>
 );
}

export default App;
