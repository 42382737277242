import React from 'react';
import './Footer.css';
import { FaTwitter, FaTelegram, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2>ToughBar</h2>
          <p>
            Leading innovation in blockchain and decentralized finance, ToughBar
            offers a unique and engaging platform for enthusiasts and investors.
          </p>
        </div>

        <div className="footer-section social">
          <h2>Contact Us</h2>
          <div className="social-icons">
            <a href="https://twitter.com/toughbar" className="social-icon" target="_blank" rel="noopener noreferrer">
              <FaTwitter /> @ToughBar
            </a>
            <a href="https://t.me/toughbar" className="social-icon" target="_blank" rel="noopener noreferrer">
              <FaTelegram /> @ToughBar
            </a>
            <a href="https://instagram.com/toughbar" className="social-icon" target="_blank" rel="noopener noreferrer">
              <FaInstagram /> @ToughBar
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 ToughBar. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
