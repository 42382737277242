import { useSelector, useDispatch } from 'react-redux'

import config from '../config.json'

import { loadTokens } from '../store/interactions'

const Markets = () => {
	const provider = useSelector(state => state.provider.connection)
	const chainId = useSelector(state => state.provider.chainId)

	const dispatch = useDispatch()

    const marketHandler = async (e) => {
      loadTokens(provider, (e.target.value).split(','), dispatch)
    }


  return(
    <div className='component exchange__markets'>
      <div className='component__header'>
        <h2>Select Market</h2>
      </div>
      
      {chainId && config[chainId] ? (
        <select name="markets" id="markets" onChange={marketHandler}>
          <option value={`${config[chainId].BTbar.address},${config[chainId].WETH.address}`}>BTbar / WETH</option>
          <option value={`${config[chainId].BTbar.address},${config[chainId].DAI.address}`}>BTbar / DAI</option>
          <option value={`${config[chainId].BTbar.address},${config[chainId].LINK.address}`}>BTbar / LINK</option>
          <option value={`${config[chainId].BTbar.address},${config[chainId].USDC.address}`}>BTbar / USDC</option>
          <option value={`${config[chainId].BTbar.address},${config[chainId].WBTC.address}`}>BTbar / WBTC</option>
          <option value={`${config[chainId].BTbar.address},${config[chainId].ATp.address}`}>BTbar / ATp</option>
          <option value={`${config[chainId].ATp.address},${config[chainId].BTbar.address}`}>ATp / BTbar</option>
          <option value={`${config[chainId].ATp.address},${config[chainId].WETH.address}`}>ATp / WETH</option>
          <option value={`${config[chainId].ATp.address},${config[chainId].USDC.address}`}>ATp / USDC</option>
          <option value={`${config[chainId].ATp.address},${config[chainId].DAI.address}`}>ATp / DAI</option>
          <option value={`${config[chainId].ATp.address},${config[chainId].WBTC.address}`}>ATp / WBTC</option>
          <option value={`${config[chainId].ATp.address},${config[chainId].LINK.address}`}>ATp / LINK</option>
        </select>
      ) : (
        <div>
          <p>Not Deployed to Network</p>
        </div>
      )}
      

      <hr />
    </div>
  )
}

export default Markets;
